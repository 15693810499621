import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_object} from "@/components/base/utils";
const BaseMultiTagComponent = () => import("@/components/base/tag/BaseMultiTagComponent")

export default {
    name: "BaseSingleBlogComponent",
    mixins: [BaseRenderMixin],
    props: {
        blog_data: Object
    },
    methods: {
        make_element_tree(createElement, element_data, options = {},context = null) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)
            if (style) data.style = style
            if (props) data.props = props
            if (attrs) {
                data.attrs = attrs
                // if ('deepsel-add-to-cart' in attrs || 'deepsel-remove-from-cart' in attrs) {
                //     data.on = {}
                //     if ('deepsel-add-to-cart' in attrs) data.on.click = this.add_to_cart
                //     else if ('deepsel-remove-from-cart' in attrs) data.on.click = this.remove_from_cart
                // }
            }

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null

            if (element_data.attr && 'deepsel-multi-tag' in element_data.attr) {
                el = BaseMultiTagComponent
                props = {
                    component_data: element_data,
                    tag_data: context.tag_data
                }
            }

            return [el, props]
        },
        get_attrs(element_data) {
            if (element_data.attr) {
                let attrs = {}

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                let {blog_data} = this.$props
                if ('deepsel-blog-image' in attrs) {
                    if (attrs.src) attrs.src = blog_data.image
                } 
                if ('deepsel-blog-title' in attrs) {
                    if (attrs.src) attrs.title = blog_data.title
                }
                if ('deepsel-blog-url' in attrs) {
                    attrs.href = `/blog?blog=${blog_data.url.substring(1)}`
                    if(this.$store.state.edit_mode) delete attrs.href  // disable in edit_mode
                }

                return attrs
            } else return null
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = JSON.parse(JSON.stringify(element_data.attr.style))

                if (style.backgroundImage) {
                    if ('deepsel-blog-image' in element_data.attr) {
                        style.backgroundImage = `url(${this.$props.blog_data.image})`
                    } else if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        get_children(element_data, createElement) {
            let children = []
            let deepsel_text_data
            let {blog_data} = this.$props

            if (element_data.attr) {
                if ('deepsel-blog-title' in element_data.attr) deepsel_text_data = blog_data.title
                else if ('deepsel-blog-subtitle' in element_data.attr) deepsel_text_data = blog_data.subtitle
                else if ('deepsel-blog-author' in element_data.attr) deepsel_text_data = blog_data.author
                else if ('deepsel-blog-date' in element_data.attr) deepsel_text_data = this.$moment(blog_data.publish_date).format('llll')
                else if ('deepsel-blog-category' in element_data.attr) deepsel_text_data = blog_data.category
            }

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        let single_tag_children = false
                        if (item.attr && 'deepsel-multi-tag' in item.attr) {
                            if (single_tag_children) continue
                            single_tag_children = true
                            children.push(this.make_element_tree(createElement, item, {}, {tag_data: this.$props.blog_data.tag}))

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }

                    } else if (item.node === "text") {
                        children.push(deepsel_text_data !== undefined ? (deepsel_text_data ? deepsel_text_data : '') : item.text)
                    }
                }
            }
            return children
        },
    }
}
